import React from "react";
import "./UIFooter.css";
export const UIFooter = () => {
  return (
    <footer>
      <div className="container-footer">
        <hr className="hr-footer" />
        <div className="footer-contains">
          <p className="p-footer">
            &copy; Copyright - Natalia Sierra | All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};
