export const PortfolioProjectsMock = [
  {
    id: 1,
    name: "FullMarket",
    url: "https://fullmarket-cc3.pages.dev",
    image:"https://firebasestorage.googleapis.com/v0/b/portfolioprojects-d6dda.appspot.com/o/FullMarket.png?alt=media&token=94f38975-859b-4088-91b6-56e30ea548bc",
    headerCard: "FullMarket Project",
    info: "React Js | Python",
  },
  {
    id: 2,
    name: "Amore's Lingerie",
    url: "https://wonderful-euclid-64be61.netlify.app",
    image:"https://firebasestorage.googleapis.com/v0/b/portfolioprojects-d6dda.appspot.com/o/AmoresLingerie.jpeg?alt=media&token=22f86784-34af-41c0-a51c-c172cae6a0fb",
    headerCard: "Amore's Lingerie",
    info: "JavaScript | HTML5 | CSS3",
  },
  {
    id: 3,
    name: "Pokemon",
    url: "https://pokeapp.pages.dev/",
    image:"https://firebasestorage.googleapis.com/v0/b/portfolioprojects-d6dda.appspot.com/o/pokemon.png?alt=media&token=b971ce1e-b089-4acd-8ff7-bdec3778b156",
    headerCard: "Pokemon",
    info: "React JS | Vite",
  },
  {
    id: 4,
    name: "Rick & Morty",
    url: "https://stoic-leavitt-ecf5c6.netlify.app",
    image:"https://firebasestorage.googleapis.com/v0/b/portfolioprojects-d6dda.appspot.com/o/rmorty.png?alt=media&token=d094133c-05c1-410f-b6fb-5ae2fbc0eb1f",
    headerCard: "Rick & Morty",
    info: "React JS | HTML5 | CSS3",
  },
  // {
  //   id: 5,
  //   name: "Paginate with Pokemon",
  //   url: "",
  //   image: "https://firebasestorage.googleapis.com/v0/b/portfolioprojects-d6dda.appspot.com/o/pokepaginador.png?alt=media&token=4cf81a8b-e1a6-438a-bc55-e922fb7f8df7",
  //   headerCard: "Paginate with Pokemon",
  //   info: "React JS | HTML5 | CSS3",
  // },
  {
    id: 6,
    name: "MARVEL",
    url: "",
    image:"https://firebasestorage.googleapis.com/v0/b/portfolioprojects-d6dda.appspot.com/o/marvel.jpg?alt=media&token=fee89aec-f2bc-4dea-a377-f1b8821edc0b",
    headerCard: "MARVEL API",
    info: "React JS | Vite | HTML5 | CSS3",
  },
];
