import React from "react";
import { UIHeader } from "../../../UI/UIHomePage/UIHeader/UIHeader";
import { UISectionOne } from "../../../UI/UIHomePage/UISectionOne/UISectionOne";
import { UISectionTwo } from "../../../UI/UIHomePage/UISectionTwo/UISectionTwo";

export const FirstSection = () => {
  return (
    <>
      <UIHeader />
      <UISectionOne />
      <UISectionTwo />
    </>
  );
};
