import React from "react";
import "./UISectionOne.css";
import imgNataSierra from "../../../../Assets/Images/nataSierra.png";
import SouthIcon from "@mui/icons-material/South";

export const UISectionOne = () => {
  return (
    <div className="container-section-one">
      <div className="columns-section-one">
        <div className="first-column-container">
          <h1 className="h1-name-title">
            Natalia <br /> Sierra
            <span className="span-final-point-name">.</span>
          </h1>
          <hr className="hr-first-column" />
          <div className="social-media">
            <div className="social-media-first-column">
              <a
                href="https://github.com/NataliaSierra"
                target="_blank"
                rel="noopener noreferrer"
                className="a-social-media-action"
              >
                GitHub
                <span className="p-social-links">|</span>
              </a>
              <a
                href="https://www.linkedin.com/in/nataliasierradev/"
                target="_blank"
                rel="noopener noreferrer"
                className="a-social-media-action"
              >
                LinkedIn
              </a>
            </div>
            <div className="social-media-first-column">
              <a
                href="https://api.whatsapp.com/send/?phone=573103811650&text=¡Hola%20Natalia!%20He%20revisado%20tu%20portafolio%20y%20quiero%20hablar%20contigo.%20Estoy%20atent@%20a%20tu%20respuesta."
                target="_blank"
                rel="noopener noreferrer"
                className="a-social-media-action"
              >
                WhatsApp
                {/* <span className="p-social-links">|</span> */}
              </a>
              {/* <a href="" className="a-social-media-action">
                Dribbble
              </a> */}
            </div>
          </div>
          <div className="container-btn-contact-me">
            <a href="#contact-me">
              <button className="btn-contact-me">CONTACT ME</button>
            </a>
          </div>
        </div>
        <div className="second-column-container">
          <img
            src={imgNataSierra}
            alt="Natalia Sierra"
            className="img-second-column"
          />
        </div>
        <div className="third-column-container">
          <h3 className="h3-introduction-title">INTRODUCTION</h3>
          <h2 className="h2-profession-title">
            UI/UX Designer, <br />
            Frontend Web <br />
            Developer
          </h2>
          <p className="p-personal-description">
          My name is Natalia Sierra, I'm a Frontend developer.
          
I am passionate about beautiful interfaces and interfaces that can be implemented intuitively.
          </p>
          <div className="container-learn-action">
            <a href="#Development-stack" className="learn-more-action">
              Learn More <SouthIcon sx={{ fontSize: 16 }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
