import React from "react";
import { FirstSection } from "../../Layouts/HomePageLayouts/FirstSection/FirstSection";
import { SecondSection } from "../../Layouts/HomePageLayouts/SecondSection/SecondSection";
import { ContactMeSection } from "../../Layouts/HomePageLayouts/ContactMeSection/ContactMeSection";
import { FooterSection } from "../../Layouts/HomePageLayouts/FooterSection/FooterSection";

export const HomePage = () => {
  return (
    <>
      <FirstSection />
      <SecondSection />
      <ContactMeSection />
      <FooterSection/>
    </>
  );
};
