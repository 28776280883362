import React from "react";
import "./UIContactMe.css";

export const UIContactMe = () => {
  return (
    <>
      <div className="container-contact-me-section" id="contact-me">
        <div className="container-contact-info">
          <div className="contact-section">
            <div className="contact-title-subtitle-text">
              <h3 className="h3-contact-title">CONTACT US</h3>
            </div>
            <div className="contact-title-subtitle-text">
              <h2 className="h2-subtitle">Ways to contact me</h2>
            </div>
            <div className="contact-title-subtitle-text-p">
              {/* <p className="p-paragraph-text">
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupida non proident, sunt in culpa qui officia
              </p> */}
            </div>
          </div>
        </div>
        <div className="container-contact-box">
          <div className="contact-box">
            <div className="box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-mail"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="#dadada"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                <path d="M3 7l9 6l9 -6" />
              </svg>
              <h3 className="contact-h3">nataliasierradev@gmail.com</h3>
            </div>
            <div className="box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-phone"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="#dadada"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
              </svg>
              <h3 className="contact-h3">+ 57 310 381 1650</h3>
            </div>
            {/* <div className="box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-brand-skype"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="#dadada"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 3a9 9 0 0 1 8.603 11.65a4.5 4.5 0 0 1 -5.953 5.953a9 9 0 0 1 -11.253 -11.253a4.5 4.5 0 0 1 5.953 -5.954a8.987 8.987 0 0 1 2.65 -.396z" />
                <path d="M8 14.5c.5 2 2.358 2.5 4 2.5c2.905 0 4 -1.187 4 -2.5c0 -1.503 -1.927 -2.5 -4 -2.5s-4 -1 -4 -2.5c0 -1.313 1.095 -2.5 4 -2.5c1.642 0 3.5 .5 4 2.5" />
              </svg>
              <h3 className="contact-h3">Live: shail_</h3>
            </div>
            <div className="box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-brand-twitter"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="#dadada"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c0 -.249 1.51 -2.772 1.818 -4.013z" />
              </svg>
              <h3 className="contact-h3">@NataliaSierra</h3>
            </div>
            <div className="box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-brand-dribbble"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="#dadada"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M9 3.6c5 6 7 10.5 7.5 16.2" />
                <path d="M6.4 19c3.5 -3.5 6 -6.5 14.5 -6.4" />
                <path d="M3.1 10.75c5 0 9.814 -.38 15.314 -5" />
              </svg>
              <h3 className="contact-h3">NataliaSierra</h3>
            </div> */}
          </div>
        </div>
        <div className="btn-top">
          <a href="#Home">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-square-chevron-up"
              width="48"
              height="48"
              viewBox="0 0 24 24"
              stroke-width="1"
              stroke="#e4aa48"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
              <path d="M9 13l3 -3l3 3" />
            </svg>
          </a>
        </div>
      </div>
    </>
  );
};
