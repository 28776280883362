import React from "react";
import "./UISectionTwo.css";
import { DevelopmentStackMock } from "../../../../Services/DevelopmentStack/DevelopmentStackMock";

export const UISectionTwo = () => {
  return (
    <>
      <div className="container-section-two" id="Development-stack">
        <div className="services-title-container">
          <h4 className="h4-services-title">DEVELOPMENT STACK</h4>
        </div>
        <div className="services-subtitle-container">
          <h2 className="h2-subtitle-services">My development tools</h2>
        </div>
        <div className="services-paragraph-container">
          <p className="p-services-paragraph">
          These are my development tools.
          </p>
        </div>
      </div>
      <div className="services-cards">
        <div className="container-services-cards">
          {DevelopmentStackMock.map((item, index) => (
            <div className="container-card" key={index}>
              <div className="card">
                <div className="container-img-card">
                  <img
                    src={item?.image}
                    className="img-card-stack"
                    alt={item?.info}
                  />
                  {/* <div className="container-img-hover">
                    <img
                      src={YellowPen}
                      className="img-card"
                      alt="Yellow Pen"
                    />
                  </div> */}
                </div>
                <h4 className="h4-card-subtitle">{item?.nameStack}</h4>
                {/* <p className="info-card">{item?.info}</p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
