import React from "react";
import { UIPortfolioSectionCards } from "../UIPortfolioSectionCards/UIPortfolioSectionCards";
import "./UIPortfolioSection.css";

export const UIPortfolioSection = () => {
  return (
    <>
      <div className="container-section-three" id="my-portfolio">
        <div className="portfolio-section">
          <div className="portfolio-title-subtitle-text">
            <h3 className="h3-portfolio">PORTFOLIO</h3>
          </div>
          <div className="portfolio-title-subtitle-text">
            <h2 className="h2-subtitle">My collection of projects</h2>
          </div>
          {/* <div className="portfolio-title-subtitle-text">
            <p className="p-paragraph-text">
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupida non proident, sunt in culpa qui officia
            </p>
          </div> */}
        </div>
      </div>
      <div className="container-cards-projects">
        <div className="cards-projects-section">
          <UIPortfolioSectionCards />
        </div>
      </div>
    </>
  );
};
