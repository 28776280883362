import React, { useState } from "react";
import "./UIHeader.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Divider from "@mui/material/Divider";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MenuIcon from "@mui/icons-material/Menu";
import NS_logo from '../../../../Assets/Images/HeaderImage/NS_logo.png'
import IconButton from "@mui/material/IconButton";

export const UIHeader = () => {
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(true);

  function handlerOpen() {
    setOpen(true);
    if (open === true) {
      handlerClose();
    }
  }

  function handlerClose() {
    setClose(true);
    setOpen(false);
  }
  return (
    <header id="Home">
      {close && ""}
      <nav>
        <ul className="ul-container">
          <div className="container-img-header">
            <img src={NS_logo} alt="Natalia Sierra" className="img-header" />
          </div>
          <li>
            <a href="/#" rel="noopener noreferrer" className="nav-link">
              Home
            </a>
          </li>
          <li>
            <a href="/#Development-stack" className="nav-link">
              Stack
            </a>
          </li>
          <li>
            <a href="/#my-portfolio" className="nav-link">
              Portfolio
            </a>
          </li>
          {/* <li>
            <a href="" className="nav-link">
              Pages
            </a>
          </li> */}
          <li>
            <a href="/#contact-me" className="nav-link">
              Contact
            </a>
          </li>
          <Divider orientation="vertical" flexItem />
          <div className="social-media-header">
            <li>
              <a href="https://www.linkedin.com/in/nataliasierradev/" target="_blank" rel="noopener noreferrer" className="nav-link">
                <LinkedInIcon />
              </a>
            </li>
            {/* <li>
              <a href="" className="nav-link">
                <FacebookIcon />
              </a>
            </li> */}
            <li>
              <a href="https://api.whatsapp.com/send/?phone=573103811650&text=¡Hola%20Natalia!%20He%20revisado%20tu%20portafolio%20y%20quiero%20hablar%20contigo.%20Estoy%20atent@%20a%20tu%20respuesta." target="_blank" rel="noopener noreferrer" className="nav-link">
                <WhatsAppIcon />
              </a>
            </li>
          </div>
        </ul>
      </nav>

      <div className="container-mobile-menu">
        <div className="container-img-header-mobile">
          <img src={NS_logo} alt="Natalia Sierra" className="img-header-mobile" />
        </div>
        <div className="mobile-menu">
          <IconButton onClick={handlerOpen} size="small" sx={{ ml: 2 }}>
            <MenuIcon className="nav-link-mobile" />
          </IconButton>
        </div>
      </div>
      {open === true && (
        <div className="container-mobile-menu">
          <nav className="nav-mobile-menu">
            <ul className="ul-mobile-menu">
              <li>
                <a href="/#" rel="noopener noreferrer" className="nav-link">
                  Home
                </a>
              </li>
              <li>
                <a href="/#Development-stack" className="nav-link">
                  Stack
                </a>
              </li>
              <li>
                <a href="/#my-portfolio" className="nav-link">
                  Portfolio
                </a>
              </li>
              <li>
                <a href="/#contact-me" className="nav-link">
                  Contact
                </a>
              </li>
              <div className="social-media-header">
                <li>
                  <a href="https://www.linkedin.com/in/nataliasierradev/" target="_blank"  rel="noopener noreferrer" className="nav-link">
                    <LinkedInIcon />
                  </a>
                </li>
                {/* <li>
                  <a href="" className="nav-link">
                    <FacebookIcon />
                  </a>
                </li> */}
                <li>
                  <a href="https://api.whatsapp.com/send/?phone=573103811650&text=¡Hola%20Natalia!%20He%20revisado%20tu%20portafolio%20y%20quiero%20hablar%20contigo.%20Estoy%20atent@%20a%20tu%20respuesta." target="_blank" rel="noopener noreferrer" className="nav-link">
                    <WhatsAppIcon />
                  </a>
                </li>
              </div>
            </ul>
          </nav>
        </div>
      )}
    </header>
  );
};
