import React from "react";
import { UIPortfolioSection } from "../../../UI/UIHomePage/UIPortfolioSection/UIPortfolioSection";

export const SecondSection = () => {
  return (
    <>
      <UIPortfolioSection />
    </>
  );
};
