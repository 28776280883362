import React from "react";
import "./UIPortfolioSectionCards.css";
import { PortfolioProjectsMock } from "../../../../Services/PortfolioProjects/PortfolioProjectsMock";

export const UIPortfolioSectionCards = () => {
  return (
    <>
      {PortfolioProjectsMock.map((item, index) => (
        <a href={item?.url} target="_blank" rel="noopener noreferrer">
          <div className="project-card" key={index}>
            <div className="content">
              <div className="back">
                <div className="back-content">
                  <img
                    src={item?.image}
                    className="img-card"
                    alt={item?.name}
                  />
                  <strong>{item?.name}</strong>
                </div>
              </div>
              <div className="front">
                <div className="img">
                  <div className="circle"></div>
                  <div className="circle" id="right"></div>
                  <div className="circle" id="bottom"></div>
                </div>
                <div className="front-content">
                  <small className="badge">{item?.headerCard}</small>
                  <div className="description">
                    <div className="title">
                      <p className="title">
                        <strong>{item?.name}</strong>
                      </p>
                    </div>
                    {/* <p className="card-footer">React JS &nbsp; | &nbsp; API</p> */}
                    <p className="card-footer">{item?.info}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      ))}
    </>
  );
};
