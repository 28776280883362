export const DevelopmentStackMock = [
  {
    id: 1,
    nameStack: "React",
    image: "https://firebasestorage.googleapis.com/v0/b/portfoliostack-3bc3e.appspot.com/o/pngReact.png?alt=media&token=04034fd7-0d21-4b35-8b2e-eca00bb8a6b2",
    info: "React",
  },
  {
    id: 2,
    nameStack: "JavaScript",
    image: "https://firebasestorage.googleapis.com/v0/b/portfoliostack-3bc3e.appspot.com/o/js.png?alt=media&token=3353d22c-71d5-4b70-9645-60eb967f4a89",
    info: "JavaScript",
  },
  {
    id: 3,
    nameStack: "HTML 5",
    image: "https://firebasestorage.googleapis.com/v0/b/portfoliostack-3bc3e.appspot.com/o/html.png?alt=media&token=03f20acc-fcdf-47d2-aa60-09edf06ed09e",
    info: "HTML 5",
  },
  {
    id: 4,
    nameStack: "CSS 3",
    image: "https://firebasestorage.googleapis.com/v0/b/portfoliostack-3bc3e.appspot.com/o/css.png?alt=media&token=c0837a99-3171-46b7-b9d7-e3f110bf044f",
    info: "CSS 3",
  },
  {
    id: 5,
    nameStack: "Typescript",
    image: "https://firebasestorage.googleapis.com/v0/b/portfoliostack-3bc3e.appspot.com/o/Typescript.png?alt=media&token=44af634e-6233-4d4b-9d33-eccf27b7ac65",
    info: "Typescript",
  },
  {
    id: 6,
    nameStack: "GitHub",
    image: "https://firebasestorage.googleapis.com/v0/b/portfoliostack-3bc3e.appspot.com/o/github.png?alt=media&token=53c3a02d-fc8d-433a-8943-a40d606ef671",
    info: "GitHub",
  },
  // {
  //   id: 7,
  //   nameStack: "Docker",
  //   image: "https://firebasestorage.googleapis.com/v0/b/portfoliostack-3bc3e.appspot.com/o/docker.png?alt=media&token=d77ac0f4-b2e1-409d-af6b-2d2481540bf8",
  //   info: "Docker",
  // },
  // {
  //   id: 8,
  //   nameStack: "MySQL",
  //   image: "https://firebasestorage.googleapis.com/v0/b/portfoliostack-3bc3e.appspot.com/o/mysql.png?alt=media&token=52a5407f-d4d0-4d66-9b10-891775dd8458",
  //   info: "MySQL",
  // },
  {
    id: 9,
    nameStack: "Material UI",
    image: "https://firebasestorage.googleapis.com/v0/b/portfoliostack-3bc3e.appspot.com/o/mui.png?alt=media&token=e7657db9-7927-431d-9608-be54b0293a79",
    info: "Material UI",
  },
  {
    id: 10,
    nameStack: "Bootstrap",
    image: "https://firebasestorage.googleapis.com/v0/b/portfoliostack-3bc3e.appspot.com/o/bootstrap.png?alt=media&token=5b5cc6d3-c95b-46a4-b822-853ef5b0a30e",
    info: "Bootstrap",
  },
  {
    id: 11,
    nameStack: "Tailwind",
    image: "https://firebasestorage.googleapis.com/v0/b/portfoliostack-3bc3e.appspot.com/o/tailwind.png?alt=media&token=cfb73953-50d0-4b1a-906d-44bcc8aaaa1e",
    info: "Tailwind",
  },
];
